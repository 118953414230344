import styled from 'styled-components';
import { FlexRowCenter } from '@styles/global-styles';

export const DialogGuideWrapper = styled.div`
  &.popupGuide {
    ${FlexRowCenter};
    background: #fff;
    flex-flow: column;
    .--logo {
      width: max-content;
      margin: 0 auto;
      img {
        width: 120px;
      }
    }
    .--footer {
      margin-top: -1rem;
    }
    .--wrapper {
      background: transparent;
      color: #fff;
      padding: 2.5rem 3rem 0;
      flex-flow: column;
    }

    .itemGuide {
      /* width: 33.333%; */
      text-align: center;
      border-radius: 24px;
      overflow: hidden;
      border: 1px solid #a1e1ff;
      filter: drop-shadow(0px 2px 20px rgba(18, 162, 249, 0.35));

      .--icon {
      }
      span {
      }
    }

    .--close {
    }

    .--title {
      text-align: center;

      h1 {
        font-weight: initial;
        color: #fff;
        line-height: 120%;
      }
      strong {
        display: block;
      }
    }

    .--content {
    }

    .--sliderWrapper {
      display: flex;
      gap: 1.5rem;
      justify-content: center;
      margin: 2.5rem 0 0;
    }

    .--buttons {
      display: flex;
      flex-flow: column;
      align-items: center;

      .btnSecondary {
        width: max-content;
        background: var(--color-blue);
        color: #fff;
      }

      .btnTxtLink {
        color: #fff;
      }
    }
    @media (max-width: 768px) {
      /* .itemGuide {
        width: 50%;
      } */
    }
  }
`;
